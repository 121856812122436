<template>
  <div class="cui__uncontent">
    <div class="biz-container">
      <CatalogueByCategory />
    </div>
  </div>
</template>

<script>
import CatalogueByCategory from '@/components/Purchase/Catalogue/CatalogueByCategory.vue'

export default {
  name: 'purchase.category',
  components: { CatalogueByCategory },
}
</script>
